export default {
  "navbar": {
    "mealKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitybos rinkiniai"])},
    "weeksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savaitės meniu"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kainos"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUK"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktai"])},
    "checkoutOrderSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiskaitymas / Užsakymas"])},
    "dishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalai"])},
    "allergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergenai"])},
    "dislikedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemėgstami produktai"])},
    "macronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makroelementai"])},
    "dietPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dietos planas"])},
    "dishNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalų skaičius"])},
    "otherRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiti pageidavimai"])},
    "individualOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualus užsakymas"])},
    "otherSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiti nustatymai"])},
    "orderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymų valdymas"])},
    "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos"])},
    "blogArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogo straipsniai"])},
    "adminsProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administratoriaus profilis"])},
    "kitOrderSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinio užsakymo nustatymai"])},
    "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES parama"])},
    "cristmasEveForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kūčių rinkinys"])},
    "adminsPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puslapiai"])},
    "adminCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miestai"])}
  },
  "buttons": {
    "lookUpKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apžiūrėti mitybos rinkinus"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakyti"])},
    "makeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pateikti užklausą"])},
    "physicalActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizinis aktyvumas"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgal"])},
    "payCompleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėti / Atlikti užsakymą"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultatai"])},
    "changeParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakeisti parametrus"])},
    "moreArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daugiau straipsnių"])},
    "allQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi klausimai"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siųsti"])},
    "weightMaintain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išlaikyti svorį"])},
    "weightLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numesti svorio"])},
    "weightGain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priaugti svorio"])},
    "addNewDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti naują patiekalą"])},
    "resetToDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atstatyti į pradinį"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atnaujinti"])},
    "updateLowercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atnaujinti"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ištrinti"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurti"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukti"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsaugoti"])},
    "createNewDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurti naują patiekalą"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodyti"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slėpti"])},
    "addNewKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti naują rinkinį"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti"])},
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti nuotrauką"])},
    "makeThumbnail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavyzdinė nuotrauka"])},
    "addOrderTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti užsakymo laiką"])},
    "addNewItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti naują vienetą"])},
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti naują"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas"])},
    "evaluateDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įvertinti patiekalą"])},
    "showAllergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodyti alergenus"])},
    "showCalorieNorm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodyti kalorijų normą"])},
    "addDeliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti pristatymo laiką"])},
    "addFaqItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti DUK elementą"])},
    "resetOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atstatyti nustatymus"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti į krepšelį"])},
    "makeAnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlikti užsakymą"])},
    "updateAndMakeAnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atnaujinti ir užsakyti"])},
    "addCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti kodą"])},
    "continueBuying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papildyti užsakymą"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiskaityti"])},
    "setAccountInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudoti savo duomenis"])},
    "orderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymų valdymas"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grįžti"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakeisti"])},
    "addFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti failą"])},
    "changeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakeisti failą"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo detalės"])},
    "savePriceApproveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsaugoti kainą / Patvirtinti"])},
    "cantBeProcessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo negalima atlikti"])},
    "makePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlikti mokėjimą"])},
    "approvePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti mokėjimą"])},
    "paymentNotMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas neatliktas"])},
    "saveComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsaugoti komentarus"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiūrėti"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreguoti"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taip"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sąskaita faktūra"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabaigta"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumokėta"])},
    "notPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesumokėta"])},
    "saveNewAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsaugoti naują adresą"])},
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungti"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registruotis"])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atstatyti slaptažodį"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atnaujinti slaptažodį"])},
    "newBlogPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naujas blogo straipsnis"])},
    "iAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sutinku"])},
    "viewCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peržiūrėti krepšelį"])},
    "newItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naujas vienetas"])},
    "usePoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudoti taškus"])},
    "resetPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atstatyti taškus"])},
    "pointsApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taikyti taškai"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registruotis"])},
    "continueOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tęsti užsakymą"])},
    "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukti užsakymą"])},
    "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atnaujinti užsakymą"])},
    "confirmReturnedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinti grąžintą sumą"])},
    "regularOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standartiniai užsakymai"])},
    "individualOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualūs užsakymai"])},
    "changeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keisti detales"])},
    "changeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreguoti užsakymą"])},
    "moreSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daugiau nustatymų"])},
    "newPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naujas puslapis"])},
    "registerOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsiregistruoti arba prisijungti"])},
    "payWithoutRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėti be registracijos"])},
    "newCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naujas miestas"])}
  },
  "weekDays": {
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirmadienis"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antradienis"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trečiadienis"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketvirtadienis"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penktadienis"])},
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pr"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tr"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ke"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pe"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Še"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se"])}
  },
  "common": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašalinti"])},
    "kgWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg/sav"])},
    "kcalDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kcal/dienai"])},
    "kcal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kcal"])},
    "meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["patiekalai"])},
    "meals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["patiekalai"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuo"])},
    "forADay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienai"])},
    "havePescatarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yra ir pesketariškas"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyras"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moteris"])},
    "requiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Privalomi laukai"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metai"])},
    "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cm"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vardas"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavardė"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės pavadinimas"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. paštas"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žinutė"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonas"])},
    "ageFrom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Amžius (nuo ", _interpolate(_named("MIN_AGE")), " iki ", _interpolate(_named("MAX_AGE")), ")"])},
    "heightCm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ūgis (cm)"])},
    "weightKg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svoris (kg)"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrai"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rūšiuoti pagal"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepasirinkta"])},
    "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pusryčiai"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pietūs"])},
    "lunch1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pietūs 1"])},
    "lunch2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pietūs 2"])},
    "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vakarienė"])},
    "snack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užkandis"])},
    "snack_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užkandis 1"])},
    "snack_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užkandis 2"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neapibrėžta"])},
    "newestCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naujausias sukurtas pirma"])},
    "oldestCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seniausias sukurtas pirma"])},
    "highestEvaluated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aukščiausiai įvertintas pirma"])},
    "lowestEvaluated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žemiausiai įvertintas pirma"])},
    "mealUndefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalas neapibrėžtas"])},
    "allergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergenai"])},
    "calorieNorm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalorijų norma (kcal)"])},
    "carbohydrates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angliavandeniai (g)"])},
    "fats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riebalai (g)"])},
    "proteins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baltymai (g)"])},
    "votes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("votes")), " įvertinimai"])},
    "dishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalo pavadinimas"])},
    "dishDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalo aprašymas"])},
    "regularMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguliarus meniu"])},
    "uniqueMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalus meniu"])},
    "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar tikrai norite ištrinti"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguliarus"])},
    "vegetarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesketariškas"])},
    "pescatarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesketariškas"])},
    "noItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nėra vienetų"])},
    "noAllergensDefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergenai nenurodyti"])},
    "extendAllSections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išskleisti visus pasirinkimus"])},
    "kitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinio pavadinimas"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprašymas"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaina (€)"])},
    "combinationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinacijos pavadinimas"])},
    "priceForDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienos kaina (€)"])},
    "thumbnail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavyzdinė nuotrauka"])},
    "lastOrderDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paskutinė užsakymo diena"])},
    "deliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo data"])},
    "orderTimeFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo laikas (nuo)"])},
    "orderTimeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo laikas (iki)"])},
    "menuName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meniu pavadinimas"])},
    "macronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makroelementai"])},
    "mealNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalų skaičius"])},
    "daysAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienos kiekis"])},
    "rateDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įvertinti patiekalą"])},
    "andNutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ir maistinės medžiagos)"])},
    "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo būdas"])},
    "deliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo laikas"])},
    "deliveryTimeFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo laikas (nuo)"])},
    "deliveryTimeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo laikas (iki)"])},
    "addPercentageToIncreaseKitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti rinkinio kainos padidėjimą procentais"])},
    "additions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priedai"])},
    "deliveryMethodsOnOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo būdai (aktyvu/neaktyvu)*"])},
    "deliveryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo kaina"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PVM"])},
    "deliveryTimeSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo laiko nustatymai"])},
    "deliveryDateSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo datos nustatymai"])},
    "paymentsOnOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimai (aktyvu/neaktyvu)*"])},
    "montonio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio"])},
    "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankinis pavedimas"])},
    "cashPaymentToCourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grynais (mokėjimas kurjeriui)"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grynais"])},
    "timerSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laikmačio nustatymai*"])},
    "disabledHideTimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktyvuoti/paslėpti laikmatį"])},
    "generalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bendri nustatymai"])},
    "regularKitsSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguliarių rinkinių nustatymai"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaida"])},
    "discountCodesAndPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Nuolaidų kodai ir taškai)"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viso"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarpinė suma"])},
    "withoutAllergen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Be ", _interpolate(_named("allergen"))])},
    "pleaseWaitAccountCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prašome palaukti kol paskyra yra kuriama (prisijungiama)"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų užsakymas"])},
    "vatAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Pridėta ", _interpolate(_named("vat")), " PVM)"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemokamas"])},
    "freeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemokamas pristatymas"])},
    "discountApplied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), _interpolate(_named("sign")), " nuolaidos kodas pritaikytas"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čia"])},
    "discountCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos kodas"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymas"])},
    "forUniqueKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(unikaliems rinkiniams)"])},
    "pricesShownForDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kainos rodomos ", _interpolate(_named("amount")), " dienai(-oms)"])},
    "phoneNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono nr."])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurti paskyrą"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresas"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gatvė"])},
    "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namo numeris"])},
    "house_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namo numeris"])},
    "apartmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buto numeris"])},
    "apartment_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buto numeris"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aukštas"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašto kodas"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašto kodas"])},
    "extraInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papildoma informacija"])},
    "descriptionExtraInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprašymas / papildoma informacija"])},
    "extra_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papildoma informacija"])},
    "buyerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkėjo informacija"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas"])},
    "iAgreeWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aš sutinku su"])},
    "orderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas pateiktas!"])},
    "pricesWasUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kainos atnaujintos!"])},
    "timerUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laikmatis atnaujintas"])},
    "paymentFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo filtras"])},
    "orderStatusFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo statuso filtras"])},
    "orderPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo kaina (EUR)"])},
    "orderedKitComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinio komentarai"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsijungti"])},
    "paymentProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas apdorojamas"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatumo politika"])},
    "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudojimosi taisyklėmis"])},
    "termsAndConditionsLowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naudojimosi taisyklėmis"])},
    "404_pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deja puslapis kurio ieškote neegzistuoja. Pasirinkite vieną iš apačioje pateiktų nuorodų norėdami rasti reikiamą informaciją"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dabartinis slaptažodis"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite naują slaptažodį"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinkite naują slaptažodį"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iš"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultatų nėra"])},
    "cantCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nėra galimybės kopijuoti"])},
    "linkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuoroda nukopijuota!"])},
    "kitsTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinių bendra"])},
    "lookUpPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieškoti pašto kodo"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vardas"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavardė"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės pavadinimas"])},
    "sortByTrashed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archyvas"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atkurti"])},
    "iAgreeWithContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aš sutinku, mano nurodytais kontaktais,\ngauti informaciją dėl mano užsakymo vykdymo eigos"])},
    "iAgreeWithDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aš sutinku, mano nurodytais kontaktais,\ngauti man pritaikytus nuolaidų pasiūlymus"])},
    "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės pavadinimas"])},
    "business_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės kodas"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paieška"])},
    "client_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliento informacija"])},
    "delivery_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo informacija"])},
    "business_workers_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbuotojų skaičus"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono numeris"])}
  },
  "errors": {
    "fieldIsRequired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("key")), " laukas yra privalomas"])},
    "minMaxAge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("key")), " laukas turi būti nuo ", _interpolate(_named("MIN_AGE")), " iki ", _interpolate(_named("MAX_AGE"))])},
    "incorrectImageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netinkamas nuotraukos tipas! Prašome įkelti kitą"])},
    "incorrectFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netinkamas failo tipas! Prašome įkelti kitą"])},
    "discountCodeAlreadyApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos kodas jau pridėtas"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šis laukas yra privalomas"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Daugiau nei ", _interpolate(_named("amount")), " rašmenys privalomi jei pildomas šis laukas"])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Šis laukas neturėtų būti didesnis nei ", _interpolate(_named("amount")), " simbolių"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netinkamas el. pašto adresas"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netinkamas telefono numerio formatas. Numeris turi prasidėti +3706"])},
    "someFieldsAreNotFilledCorrectly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tam tikri laukai neteisingai užpildyti"])},
    "i_agreen_with_term_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privalote sutikti su naudojimosi taisyklėmis."])}
  },
  "order": {
    "kit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinys"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymas"])},
    "buyerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duomenys"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pateikti"])},
    "extraAdditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papildomi priedai"])},
    "typeSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koks rinkinio tipas domintų?"])},
    "kitSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koks rinkinys domintų?"])},
    "personalMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asmeninis meniu"])},
    "personalMenuDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkdami “Asmeninis meniu” privalote pridėti meniu turinį bent vienu iš apačioje patektų būdų ir nuspausti “Išsaugoti”, tam kad galėtumėte tęsti užsakymą"])},
    "otherCalorieAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitas kalorijų kiekis"])},
    "orderTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo laikotarpis"])},
    "numberOfKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinių kiekis"])},
    "individualRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualūs pageidavimai"])},
    "cantFindInList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nerandate sąraše?"])},
    "macronutrientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite dienos makroelementų kiekį kurį norėtumėte suvartoti arba įrašykite komentarus detalesniems poreikiams"])},
    "proteinsGram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baltymai (g)"])},
    "fatsGram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riebalai (g)"])},
    "carbohydratesGram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angliavandeniai (g)"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarai"])},
    "otherDietPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitas dietos planas"])},
    "specificDishNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konkretus patiekalų skaičius"])},
    "otherRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiti pageidavimai"])},
    "resetAllSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atstatyti pasirinkimus"])},
    "clearSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Išvalyti pasirinkimą"])},
    "individualOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualus užsakymas"])},
    "deliveryDateHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo data negali būti ankstesnė kaip 3 dienos nuo einamosios dienos"])},
    "deliveryInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo informacija"])},
    "orderPaidSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas sėkmingai apmokėtas"])},
    "orderRequestPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo užklausa pateikta!"])},
    "orderPriceHasChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasikeitė rinkinio kaina"])},
    "orderPriceHasChangedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informuojame, kad pasikeitė rinkinio (ar vieno iš rinkinių) kaina. Kaina jūsų užsakyme atnaujinta"])}
  },
  "placeholder": {
    "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų vardas"])},
    "enterYourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų vardas"])},
    "enterSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų pavardė"])},
    "enterCompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės pavadinimas"])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų el. paštas"])},
    "enterMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų žinutę"])},
    "enterAge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jūsų amžius (nuo ", _interpolate(_named("MIN_AGE")), " iki ", _interpolate(_named("MAX_AGE")), ")"])},
    "enterHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų ūgis (cm)"])},
    "enterWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų svoris (kg)"])},
    "enterDishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite patiekalo pavadinimą"])},
    "enterDishDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite patiekalo aprašymą"])},
    "enterCalorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite kalorijų normą (kcal)"])},
    "enterCarbohydrates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite angliavandenius (g)"])},
    "enterFats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite riebalus (g)"])},
    "enterProteins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite baltymus (g)"])},
    "chooseDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite patiekalą"])},
    "enterKitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite rinkinio pavadinimą"])},
    "enterKitDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite rinkinio aprašymą"])},
    "enterPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite kainą (€)"])},
    "enterCombinationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite kombinacijos pavadinimą"])},
    "enterPriceForDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite dienos kainą (€)"])},
    "enterMenuName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite meniu pavadinimą"])},
    "enterAmountOfDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite dienų skaičių"])},
    "increaseInPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kainos padidėjimas"])},
    "deliveryPriceWorkingDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo kaina - darbo diena (EUR)"])},
    "deliveryPriceWeekendDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo kaina - savaitgalis/šventė (EUR)"])},
    "enterDeliveryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite pristatymo kainą"])},
    "vatForKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinių PVM (%)"])},
    "vatForDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo PVM (%)"])},
    "timerStartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laikmačio pradžios laikas"])},
    "timerEndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laikmačio pabaigos laikas"])},
    "enterDiscountCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite nuolaidos kodą"])},
    "enterPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite telefono numerį"])},
    "enterInformationToCourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite informaciją kurjeriui"])},
    "enterStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite gatvę"])},
    "enterApartmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite buto numerį"])},
    "enterFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite aukštą"])},
    "enterPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite pašto kodą"])},
    "enterExtraInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite papildomą informaciją"])},
    "enterOrderPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite užsakymo kainą (EUR)"])},
    "enterOrderedKitComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakyto rinkinio komentarai"])},
    "enterEmailAddressOrUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. paštas arba vartotojo vardas"])},
    "enterEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų el. paštas"])},
    "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų slaptažodis"])},
    "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naujas slaptažodis"])},
    "enterCurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dabartinis slaptažodis"])},
    "enterConfirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinkite naują slaptažodį"])},
    "uniqueKitDeliveryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalaus rinkinio pristatymo kaina"])},
    "regularKitDeliveryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguliaraus rinkinio pristatymo kaina"])},
    "enterArticleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straipsnio pavadinimas"])},
    "selectYourCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your city"])},
    "sectionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekcijos pavadinimas"])},
    "enterPromotionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašyti reklaminį tekstą"])},
    "enterPurchaseDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašykite pirkimų nuolaidų taškus (už 1 EUR)"])},
    "enterMaxDiscountPointUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite maksimalų nuolaidų taškų panaudojimą (%)"])},
    "enterReferralDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įrašyti rekomendacijos nuolaidos taškus"])},
    "enterReferralReceiverDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite rekomendacijos gavėjo nuolaidų taškus"])},
    "enterPageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite puslapio pavadininmą"])},
    "enterDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite data"])},
    "enterSlugName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite puslapio adresą (neprivaloma)"])},
    "enterBusinessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite įmonės pavadinimą"])},
    "enterBusinessCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite įmonės kodą"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. paštas, Vardas, Pavardė"])},
    "enterBusinessWorkersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite darbuotojų skaičių"])},
    "enterPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite telefono numerį"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos kodas"])},
    "enterCityPreOrderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite išsankstinio užsakymo žinutę"])},
    "enterCityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite miesto pavadinimą"])}
  },
  "main": {
    "previewSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sveikos mitybos rinkiniai visai dienai tiesiai į namus"])}
    },
    "timerSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakykite rinkinį jau"])},
      "day": {
        "Tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rytojui"])},
        "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirmadieniui"])},
        "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antradieniui"])},
        "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trečiadieniui"])},
        "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketvirtadieniui"])},
        "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penktadieniui"])}
      }
    },
    "howItWorksSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaip tai veikia"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistemos veikimo principas"])},
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsirinkite arba susikurkite kasdienį mitybos planą"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes paruošime patiekalus"])},
      "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinius pristatysime jums kiekvieną darbo dieną"])}
    },
    "mealKitsSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitybos rinkiniai"])}
    },
    "calorieSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalorijų skaičiuoklė"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametrai"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktyvumas"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezultatai"])},
      "generalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bendra informacija"])},
      "physicalActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizinis aktyvumas"])},
      "exercise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mankšta"])},
      "exerciseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 15-30 min. padidėjusio širdies dažnio veikla"])},
      "intenseExercise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intensyvi mankšta"])},
      "intenseExerciseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 45-120 min. padidėjusio širdies dažnio veikla"])},
      "veryIntenseExercise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labai intensyvi mankšta"])},
      "veryIntenseExerciseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- +2 val. padidėjusio širdies dažnio veikla"])},
      "chooseSuitOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite variantą labiausiai atitinkantį jūsų poreikius"])},
      "mildWeightLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengvas svorio kritimas"])},
      "weightLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svorio kritimas"])},
      "extremeWeightLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didelis svorio kritimas"])},
      "mildWeightGain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengvas svorio augimas"])},
      "weightGain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svorio augimas"])},
      "extremeWeightGain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didelis svorio augimas"])},
      "sedentary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėdimas"])},
      "sedentaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- mažai arba jokios mankštos"])},
      "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lengvas"])},
      "lightDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- mankšta 1-3 kartus per savaitę"])},
      "moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidutinis"])},
      "moderateDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- mankšta 4-5 kartus per savaitę"])},
      "veryActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labai aktyvus"])},
      "veryActiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- intensyvi mankšta 6-7 kartus per savaitę"])},
      "extraActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ypatingai aktyvus"])},
      "extraActiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- labai intensyvi mankšta arba fizinis darbas"])},
      "calorieConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalorijų suvartojimas"])},
      "basedOnCalories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priklausomai nuo kalorijų"])},
      "extremeWeightLossInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norėdami numesti 1 kg svorio ar daugiau per savaitę, pasitarkite su gydytoju, nes tam reikia suvartoti mažiau kaloriįų nei minimalus rekomenduojamas 1,500 kalorijų kiekis per dieną."])}
    },
    "blogSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogas"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sveikos mitybos patarimai, informacija apie įvairias dietas"])}
    },
    "faqSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DUK"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ko mūsų klausia (Dažniausiai užduodami klausimai)"])},
      "faqQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klausimas"])},
      "faqAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsakymas"])},
      "newSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauja sekcija"])},
      "noItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vienetų nepridėta"])},
      "deleteSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ištrinti sekciją"])},
      "changeSectionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakeisti sekcijos pavadinimą"])},
      "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sekcija"])},
      "element": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elementas"])},
      "cantFindAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nerandate ataskymo? Susisiekite"])}
    },
    "getInTouch": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susisiekite"])},
      "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ačiū už jūsų žinutę!"])},
      "modalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų žinutė buvo sėkmingai išsiųsta"])}
    }
  },
  "user": {
    "personalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asmeniniai nustatymai"])},
    "kitManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinių valdymas"])},
    "orderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymų valdymas"])},
    "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos"])},
    "calorieCalculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalorijų skaičiuoklė"])},
    "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pranešimų nustatymai"])},
    "logInSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungimų nustatymai"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atnaujinti slaptažodį"])},
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asmeninė informacija"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vartotojo vardas"])},
    "realName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vardas"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavardė"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės pavadinimas"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonė"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktai"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono nr."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. paštas"])},
    "passwordMissMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis ir pakartotinas slaptažodis nesutampa"])},
    "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingai įvykdyta!"])},
    "personalInformationChangedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asmeninė informacija sėkmingai pakeista"])},
    "passwordChangedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis sėkmingai pakeistas"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mano adresai"])},
    "createNewAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurti naują adresą"])},
    "addressName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adreso pavadinimas"])}
  },
  "dishes": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalai"])},
    "dish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["patiekalas"])},
    "editDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreguoti patiekalą"])},
    "newDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naujas patiekalas"])},
    "hideNotEvaluatedDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paslėpti neįvertintus patiekalus"])},
    "uniqueMenuDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalaus meniu patiekalas"])},
    "calories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["kcal - ", _interpolate(_named("amount"))])},
    "carbohydrates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Angliavandeniai - ", _interpolate(_named("amount")), " g"])},
    "fats": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Riebalai - ", _interpolate(_named("amount")), " g"])},
    "proteins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Baltymai - ", _interpolate(_named("amount")), " g"])},
    "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Komentaras - ", _interpolate(_named("amount"))])},
    "caloriesNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kcal nenurodyta"])},
    "proteinsNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baltymai nenurodyta"])},
    "fatsNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riebalai nenurodyta"])},
    "carbohydratesNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angliavandeniai nenurodyta"])},
    "evaluationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalo įvertinimo statusas"])},
    "noDishesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalai nepridėti"])},
    "dishNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalas nenurodytas"])},
    "caloriesAndNutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calories and nutrients"])}
  },
  "menus": {
    "weeksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savaitės meniu"])},
    "menuWillSwitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meniu bus pakeistas šeštadienį 9:00 val. ryte"])},
    "nextWeeksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitos savaitės meniu"])},
    "currentWeeksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einamosios savaitės meniu"])}
  },
  "mealKits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitybos rinkiniai"])},
    "regularKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguliarūs rinkiniai"])},
    "uniqueKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalūs rinkiniai"])},
    "regularKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguliarus rinkinys"])},
    "uniqueKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalus rinkinys"])},
    "noKitsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitybos rinkiniai nepridėti"])},
    "kit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rinkinys"])},
    "editKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreguoti mitybos rinkinį"])},
    "addNewKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti naują mitybos rinkinį"])},
    "selectMenus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkti meniu*"])},
    "selectPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkti pristatymo terminą*"])},
    "selectMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkti patiekalus*"])},
    "combinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinacijos*"])},
    "selectMinimumMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite mažiausiai 1 patiekalą"])},
    "noCombinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tinkamų kombinacijų nėra"])},
    "createCombination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurti kombinaciją"])},
    "calorieNorms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalorijų normos*"])},
    "imageGallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuotraukų galerija"])},
    "noImagesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuotraukų į galeriją nepridėta"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo detalės*"])},
    "applyDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panaudoti nuolaidą"])},
    "uniqueMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikalus meniu*"])},
    "noItemsAddedToMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Į meniu nieko neįtraukta"])},
    "showHideAfterCreating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodyti/slėpti po sukūrimo"])},
    "recommendedKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekomenduojami rinkiniai"])},
    "customMealSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualus patiekalų pasirinkimas"])},
    "chooseMealCombination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkti patiekalų kombinaciją"])},
    "thereAreNoRegularMealKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguliarių mitybos rinkinių nėra"])},
    "thereAreNoMealKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitybos rinkinių nėra"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprašymas"])},
    "macronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makroelementai"])},
    "kcalPricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kcal kaina*"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įvesti"])},
    "dayDaysPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diena (dienos kaina) (€)"])},
    "averageDailyMacronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienos makroelementų vidurkis"])},
    "chooseCalorieSizeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite kalorijų dydį:"])},
    "comparisonOfAvailableCalorieChoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galimų kalorijų pasirinkimo ir makroelementų vidutinių dienos kiekių palyginimas"])}
  },
  "individualOrder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individualus užsakymas"])},
    "hideSections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slėpti sekcijas/pasirinkimus"])},
    "personalKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asmeninis rinkinys"])},
    "dislikedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemėgstami produktai"])},
    "dietPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dietų planai"])},
    "orderPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo terminai"])},
    "otherRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiti pageidavimai"])},
    "allergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergenai"])},
    "macronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makroelementai"])},
    "dishNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalų skaičius"])},
    "caloriesAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalorijų kiekis"])},
    "orderDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo terminas"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miestas"])},
    "submitHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prieš pateikiant, patikrinkit ar nurodyta informacija teisinga"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuoroda"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų užsakymo užklausa sėkmingai pateikta"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meniu"])}
  },
  "periods": {
    "createPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukurti pristatymo terminą"])}
  },
  "deliveryMethods": {
    "to_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Į rankas"])},
    "leave_at_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palikti prie durų"])},
    "take_outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiimti lauke"])}
  },
  "paymentMethods": {
    "montonio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio"])},
    "bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banko pavedimas"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grynais (mokėjimas kurjeriui)"])},
    "disablePaymentMethodsModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar tikrai norite paslėpti šį mokėjimą?"])},
    "montonioDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio - greičiausias ir patogiausias atsiskaitymo sprendimas, yra galimybė atsiskaityti kortele"])}
  },
  "earliestDeliveryDay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anksčiausia pristatymo diena"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rytdiena"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antra diena"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trečia diena"])},
    "setDeliveryDatesManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nustatyti pristatymo dienas (pristatymo galimybę - aktyvu/neaktyvu) rankiniu būdu"])}
  },
  "cart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krepšelis"])},
    "kitType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinio tipas"])},
    "mealSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalų pasirinkimas"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodas"])},
    "allergensTooltipText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkdami alergeną iš alergenų sąrašo apačioje, jūsų meniu gali skirtis nuo savaitinio meniu. Procentai atspindi kainos padidėjimą"])},
    "forWorkingDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Už ", _interpolate(_named("amount")), " darbo dieną(-as)"])},
    "forDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Už ", _interpolate(_named("amount")), " dieną(-as)"])},
    "numberOfKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinių kiekis"])},
    "forADay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienai"])},
    "otherAllergen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kitas alergenas (įrašyti) (+", _interpolate(_named("percentage")), "%)"])},
    "withoutAllergen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Be ", _interpolate(_named("name")), " (+", _interpolate(_named("percentage")), "%)"])},
    "cartIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krepšelis tuščias"])},
    "readAboutDiscountCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skaityti daugiau apie nuolaidos kodą"])},
    "noItemsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krepšelyje nieko nepridėta"])},
    "goToMealKitsSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite mitybos rinkinį norėdami jį pridėti"])},
    "deliveryForRegularKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(reguliariems rinkiniams)"])}
  },
  "buyerInfo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkėjo informacija"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktai"])},
    "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo adresas"])},
    "youCanSaveAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūs galite išsisaugoti adresą, kad panaudotumėte jį kitą kartą"])},
    "editAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreguoti adresą"])}
  },
  "bankAccount": {
    "yourOrderNumberIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų užsakymo numeris yra"])},
    "whenMakingBankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlikdami bankinį pavedimą nurodykite užsakymo numerį."])},
    "whenWeReceivePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gavus mokėjimo pavedimą užsakymas bus apdorojamas."])},
    "paymentInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo informacija"])},
    "accountIsHeldAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sąskaita laikoma"])},
    "payseraAccountOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paysera sąskaitos savininkas"])},
    "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sąskaitos numeris"])},
    "amountToBePaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma, kurią reikia sumokėti"])}
  },
  "cashModal": {
    "youCanCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galite peržiūrėti savo užsakymo statusą"])},
    "ofUserPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["savo vartotojo panelėje"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turėkite su savimi konkrečią mokėtiną sumą kurjeriui, nes jis gali neturėti grąžos"])}
  },
  "deliveryInfo": {
    "informationToCourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacija kurjeriui"])}
  },
  "otherSettings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiti nustatymai"])},
    "orderPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo terminai"])},
    "createOrderPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti užsakymo terminus"])},
    "orderPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo terminas (skaičius dienomis)"])},
    "allergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergenai"])},
    "createAllergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti alergenus"])},
    "createAllergen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti alergeną"])},
    "allergenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergeno pavadinimas"])},
    "typeDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patiekalas"])},
    "typeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" buvo sukurta!"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" buvo ištrinta!"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" buvo atnaujinta!"])},
    "kitAdditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinio priedai"])},
    "createAdditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti priedus"])},
    "createAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti priedą"])},
    "additionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priedo pavadinimas"])},
    "additionPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priedo kaina"])},
    "priceForADay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienos kaina (€)"])},
    "addThumbnail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridėti pavyzdinę nuotrauką"])},
    "dietPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dietų planai"])},
    "createDietPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti dietų planus"])},
    "dietPlanName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dietos plano pavadinimas"])},
    "dislikedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemėgstami produktai"])},
    "createDislikedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti nemėgstamus produktus"])},
    "dislikedProductName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemėgstamo produkto pavadinimas"])},
    "combinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinacijos"])},
    "createCombinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti kombinacijas"])},
    "promotionalCardInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklaminės kortelės informacija"])},
    "promotionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklaminis tekstas"])},
    "deliveryDayBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymas dieną prieš"])},
    "deliveryDayBeforeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(pristatymas dieną prieš)"])},
    "hideDishEvaluationDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paslėpti patiekalų įvertinimą"])},
    "hideEvaluationDisplayForUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paslėpti patiekalų įvertinimą vartotojams"])},
    "evaluationDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertinimo ekranas"])}
  },
  "orderManagement": {
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo numeris"])},
    "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo statusas"])},
    "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo data"])},
    "numberOfKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinių skaičius"])},
    "kitCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rinkinys atšauktas"])},
    "kitsCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rinkinys(iai) atšauktas"])},
    "byBuyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(pirkėjo)"])},
    "byProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(tiekėjo)"])},
    "cash_payment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas kurjeriui. Turėkite tikslią sumą, nes kurjeris gali neturėti grąžos"])},
    "bank_account_payment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas banko pavedimu. Atlikus mokėjimą bus pradėtas užsakymo apdorojimas"])},
    "through_cash_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas kurjeriui"])},
    "through_montonio_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas per Montonio mokėjimų sistemą"])},
    "through_bank_account_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mokėjimas banko pavedimu"])},
    "orderDeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas atmestas"])},
    "orderCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas atmestas dėl neapmokėjimo"])},
    "responseTakesUp24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsakymas gali užtrukti iki 24 val., tuo pačiu gausite informaciją elektroniniu paštu"])},
    "pleaseMakePaymentWithin6h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norėdami, kad užsakymas būtų pradėtas vykdyti, atlikite mokėjimą per 6 val. (kitu atveju užsakymas bus atmestas)"])},
    "timeOfOrderApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo patvirtinimo laikas"])},
    "deliveryStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo pradžios data"])},
    "deliveryEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo pabaigos data"])},
    "deliveryTimeInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo laikas (valandomis)"])},
    "buyersName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkėjo vardas"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo metodas"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaina"])},
    "kitAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinių kiekis"])},
    "orderPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo kaina"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmai"])},
    "noOrdersYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymų nėra"])},
    "statuses": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas pateiktas"])},
      "order_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo užklausa"])},
      "order_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas patvirtintas"])},
      "order_approved_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(laukiama mokėjimo)"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukta"])},
      "waiting_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laukiama mokėjimo"])},
      "waiting_payment_cash_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(mokėjimas kurjeriui)"])},
      "waiting_payment_bank_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(mokėjimo patvirtinimas administratoriui)"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingai sumokėta"])},
      "not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimas neatliktas"])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas baigtas"])}
    },
    "cantProcessedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar esate tikri, kad užsakymas negali būti vykdomas?"])},
    "savePriceModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar tikrai norite išsaugoti šią užsakymo kainą?"])},
    "notPaidModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar tikrai užsakymas neapmokėtas ir norite jį atšaukti?"])},
    "paidModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar esate tikri, kad užsakymas apmokėtas ir norite jį patvirtinti?"])},
    "completedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar esate tikri, kad užsakymas baigtas?"])},
    "kitEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinių veiksmai"])},
    "kitCancellationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rinkinio atšaukimo užklausa"])},
    "discountCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos kodas"])},
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klientas"])}
  },
  "discounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidų kodai"])},
    "createLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti nuolaidų kodus"])},
    "expirationHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidų kodai nustoja galioti po 2 mėnesių (60 dienų) nuo sukūrimo datos"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos kodas"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos dydis (%)"])},
    "amountFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos dydis (€)"])},
    "multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keli nuolaidų kodai"])},
    "multipleInSingleOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kelių kodų naudojimas viename užsakyme"])},
    "mealKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite patiekalų rinkinius, kuriems bus taikoma nuolaida"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procentai"])},
    "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiksuota"])},
    "discountCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaida sukurta!"])},
    "discountDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaida ištrinta!"])},
    "createDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurti nuolaidas"])},
    "pointSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taškų nustatymai"])},
    "discountPointsExpireAfter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nuolaidų taškai baigia galioti po ", _interpolate(_named("days")), " dienų nuo pirmų taškų gavimo datos"])},
    "purchaseDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkimų nuolaidų taškai (už 1 EUR)"])},
    "pointsBuyerEarnsPerEur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Už 1 EUR pirkėjo gaunami taškai, kai jis perka rinkinius"])},
    "maxDiscountPointUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimalus nuolaidų taškų panaudojimas (%)"])},
    "maximumAmountOfOrdersValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimali užsakymų sumos vertė nuo kurios vartotojas gali atskaičiuoti nuolaidų taškus"])},
    "disableDiscountPointCollecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išjungti nuolaidų taškų rinkimą"])},
    "disableDiscountPointUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išjungti nuolaidų taškų naudojimą"])},
    "discountPointSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidų taškų nustatymai"])},
    "referralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekomendacijų nustatymai"])},
    "referralDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekomendacijų nuolaidos taškai"])},
    "howManyPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiek taškų vartotojas gauna už sėkmingą rekomendaciją"])},
    "referralReceiverDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekomendacijos gavėjo nuolaidų taškai"])},
    "discountPointsPersonWhoUsesLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidų taškai gaunami asmens pasinaudojusio rekomendacija"])},
    "referralFunctionDisable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekomendacijos funkcijos Išjungimas"])},
    "discountPointValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos taškų vertė (Eur už 1 tašką)"])},
    "howMuchValueDoesPointHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokią vertę turi 1 taškas, kai naudojamas perkant (vertė Eur)"])},
    "citiesIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasirinkite miestą(-us) kuriems galios nuolaidos kodas."])}
  },
  "contacts": {
    "companyRequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės rekvizitai"])},
    "companyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės kodas"])},
    "companyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonės adresas"])},
    "socialInsuranceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SD draudėjo kodas"])},
    "kitDeliveriesAvailableIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinius pristatome šiuose miestuose"])},
    "followUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekite mus"])}
  },
  "footer": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitybos rinkiniai tiesiai į namus! Galimas pristatymas Vilniuje, Kaune ir Klaipėdoje"])},
    "usefulInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudinga informacija"])},
    "mainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagrindinis meniu"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprendimas"])},
    "allRightsAreReserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["©️ Cityrush ", _interpolate(_named("year")), " - visos teisės saugomos"])}
  },
  "login": {
    "emailAddressOrUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. paštas arba vartotojo vardas"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pamiršote slaptažodį?"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisiminti mane"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisijungti"])}
  },
  "register": {
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El. paštas"])},
    "passwordWillBeSentToEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų paskyros slaptažodis bus išsiųstas šiuo el. pašto adresu"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų asmeninė informacija bus naudojama tik užsakymų vykdymui. Norėdami gauti daugiau informacijos, galite rasti čia - "])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privatumo politika"])}
  },
  "forgotPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pamiršote slaptažodį?"])},
    "weWillSendEmailRestorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiųsime jums el. laišką, kad atkurtume slaptažodį"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laiškas išsiųstas"])},
    "checkYourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patikrinkite savo el. paštą norėdami atkurti slaptažodį"])},
    "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slaptažodis pakeistas"])},
    "yourPasswordSuccessfullyChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų slaptažodis sėkmingai pakeistas"])}
  },
  "blogArticles": {
    "blogPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogo straipsniai"])},
    "blogPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blogo straipsnis"])},
    "noBlogArticlesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogo straipsnių nepridėta"])},
    "articleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straipsnio pavadinimas"])},
    "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straipsnis"])},
    "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuotraukos"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turinys"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogas"])},
    "blogLinkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogo nuoroda nukopijuota!"])}
  },
  "policies": {
    "cookieConsentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes naudojame slapukus, kurie padeda patogiai naudotis mūsų svetaine su visomis pagrindinėmis jos funkcijomis. Jei norite sužinoti daugiau, skaitykite mūsų"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatumo politika"])}
  },
  "termsAndConditions": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkimo pardavimo taisyklės."])},
    "paragraph01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekių pirkimo – pardavimo internetinėje parduotuvėje www.cityrush.lt taisyklės"])},
    "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Bendrosios nuostatos"])},
    "paragraph11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.1. Šios prekių pirkimo – pardavimo taisyklės (toliau – Taisyklės) jas patvirtinus Pirkėjui (susipažinus su Taisyklėmis ir pažymėjus varnelę prie teiginio „Sutinku su taisyklėmis”), yra šalims privalomas teisinis dokumentas, kuriame nustatomos Pirkėjo ir Pardavėjo teisės bei pareigos, prekių įsigijimo bei apmokėjimo už jas sąlygos, prekių pristatymo ir grąžinimo tvarka, šalių atsakomybė bei kitos su prekių pirkimu – pardavimu elektroninėje parduotuvėje internetiniu adresu www.cityrush.lt (toliau – El. parduotuvė) susijusios sąlygos."])},
    "paragraph12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.2. UAB „Londesa”, juridinio asmens kodas 304467164, registracijos adresas Architektų g. 56-101, LT-04111 Vilnius (toliau – Pardavėjas)."])},
    "paragraph13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.3. Pirkti el. parduotuvėje turi teisę (toliau – Pirkėjas):"])},
    "paragraph131": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.3.1. veiksnūs fiziniai asmenys, t.y., asmenys sulaukę pilnametystės, kurių veiksnumas nėra apribotas teismo tvarka;"])},
    "paragraph132": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.3.2. nepilnamečiai nuo 14 iki 18 metų amžiaus, tik turėdami tėvų arba rūpintojų sutikimą, išskyrus tuos atvejus, kai jie savarankiškai disponuoja savo pajamomis;"])},
    "paragraph133": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.3.3. juridiniai asmenys;"])},
    "paragraph134": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.3.4. visų aukščiau nurodytų asmenų įgaliotieji atstovai."])},
    "paragraph14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.4. Pirkėjas, patvirtindamas Taisykles, garantuoja, kad jis turi teisę pirkti prekes el. parduotuvėje."])},
    "paragraph15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.5 Sutartis tarp Pirkėjo ir Pardavėjo laikoma sudaryta nuo to momento, kai Pirkėjas el. parduotuvėje suformuoja užsakymą ir paspaudžia mygtuką „Užsakyti“."])},
    "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Pirkėjo teisės"])},
    "paragraph21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.1. Pirkėjas turi teisę pirkti prekes bei užsisakyti paslaugas el. parduotuvėje šių Taisyklių nustatyta tvarka."])},
    "paragraph22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.2. Pirkėjas turi teisę atsisakyti užsakymo šių Taisyklių nustatyta tvarka."])},
    "paragraph23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.3. Pirkėjas turi teisę grąžinti įsigytas prekes Taisyklių nustatyta tvarka."])},
    "paragraph24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.4. Pirkėjas turi kitų teisių, įtvirtintų šiose Taisyklėse."])},
    "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Pirkėjo įsipareigojimai"])},
    "paragraph31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.1. Pirkėjas, naudodamasis el. parduotuve, įsipareigoja laikytis šių Taisyklių, kitų sąlygų, aiškiai nurodytų internetinėje parduotuvėje, bei nepažeisti Lietuvos Respublikos teisės aktų."])},
    "paragraph32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.2. Pirkdamas svetainėje www.cityrush.lt, Pirkėjas įsipareigoja užtikrinti, kad pateikta informacija ir asmens duomenys nėra klaidinantys ar neteisingi."])},
    "paragraph33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.3. Pirkėjas įsipareigoja informuoti Pardavėją, jei yra alergiškas konkretiems produktams."])},
    "paragraph34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.4. Užsiregistravęs Pirkėjas įsipareigoja neperduoti tretiesiems asmenims savo prisijungimo duomenų. Jei Pirkėjo prisijungimo duomenys tampa žinomi tretiesiems asmenims, Pirkėjas privalo nedelsdamas apie tai informuoti Pardavėją per vieną darbo dieną."])},
    "paragraph35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.5. Jeigu pasikeičia Pirkėjo užsakymo formoje pateikti duomenys, jis privalo nedelsdamas juos atnaujinti."])},
    "paragraph36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.6. Pirkėjas privalo sumokėti už prekes ir priimti jas šių Taisyklių nustatyta tvarka."])},
    "section4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Asmens duomenų apsauga"])},
    "paragraph41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.1. Bet kokia informacija, kuri yra susijusi su asmens duomenimis ir kuri yra užregistruojama el. parduotuvėje, laikoma slapta ir neviešinama."])},
    "paragraph42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.2. Pirkėjas gali užsisakyti prekes el. parduotuvėje svetainėje www.cityrush.lt. Pirkėjas užsakydamas prekes atitinkamuose Pardavėjo pateikiamuose informacijos laukuose turi nurodyti prekių užsakymo tinkamam įvykdymui būtinus savo asmens duomenis: vardą, pavardę, prekių pristatymo adresą, telefono numerį ir elektroninio pašto adresą."])},
    "paragraph43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.3. Pirkėjas patvirtindamas šias Taisykles sutinka, jog 2.2. punkte nurodyti jo asmens duomenys būtų tvarkomi prekių ir paslaugų pardavimo el. parduotuvėje, Pardavėjo veiklos analizės ir tiesioginės rinkodaros tikslais."])},
    "paragraph44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.4. Pirkėjas, sutikdamas, kad jo asmens duomenys būtų tvarkomi prekių ir paslaugų pardavimo Pardavėjo elektroninėje parduotuvėje tikslu, taip pat sutinka, kad jo nurodytais elektroninio pašto adresu ir telefono numeriu būtų siunčiami informaciniai pranešimai, kurie būtini prekių užsakymui įvykdyti."])},
    "paragraph45": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["4.5. Pirkėjas, nepageidaujantis, kad jo elektroninio pašto adresas būtų tvarkomas tiesioginės rinkodaros tikslu, turi apie tai informuoti el. paštu ", _interpolate(_named("email")), "."])},
    "paragraph46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.6. Pardavėjas įsipareigoja neatskleisti Pirkėjo asmens duomenų tretiesiems asmenims, išskyrus Pardavėjo partnerius, teikiančius prekių pristatymo ar kitas, su Pirkėjo užsakymo tinkamu įvykdymu susijusias paslaugas. Visais kitais atvejais Pirkėjo asmens duomenys tretiesiems asmenims gali būti atskleidžiami tik LR teisės aktų numatyta tvarka."])},
    "paragraph47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4.7. Pardavėjo darbuotojui pateiktas Pirkėjo asmens tapatybę patvirtinantis dokumentas ir jame esantys Pirkėjo duomenys naudojami tik tinkamam asmens tapatybės identifikavimui."])},
    "section5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. Pardavėjo teisės"])},
    "paragraph51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.1. Pardavėjas turi teisę keisti ar sustabdyti svetainės www.cityrush.lt funkcijas, svetainėje esančių elementų išdėstymą, nutraukti tam tikrų svetainės funkcijų veikimą."])},
    "paragraph52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.2. Pardavėjas turi teisę keisti paslaugų, teikiamų elektroninėje parduotuvėje www.cityrush.lt, teikimo apimtį ar būdą, sustabdyti, nutraukti paslaugų ar jų dalies teikimą, apmokestinti paslaugas ar paslaugų dalį."])},
    "paragraph53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.3. Jei Pirkėjas bando pakenkti svetainės www.cityrush.lt veikimo stabilumui ir saugumui ar nevykdo savo įsipareigojimų, Pardavėjas turi teisę nedelsiant ir be išankstinio įspėjimo apriboti arba sustabdyti Pirkėjo galimybę naudotis svetaine www.cityrush.lt."])},
    "paragraph54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.4. Pardavėjas turi teisę iš anksto neįspėjęs Pirkėjo anuliuoti jo užsakymą, jeigu Pirkėjas, pasirinkęs numatytą apmokėjimo būdą, nesumoka už prekes."])},
    "paragraph55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5.5. Kai Pirkėjas pasirenka apmokėjimo būdą – grynaisiais pinigais prekių pateikimo metu, Pardavėjas, iškilus neaiškumams dėl užsakyme pateiktos informacijos, turi teisę susisiekti su Pirkėju užsakyme nurodytu elektroninio pašto adresu ar telefonu. Prekių pristatymo terminas tokiu atveju pradedamas skaičiuoti nuo susisiekimo su Pirkėju dienos. Pardavėjas turi teisę iš anksto neįspėjęs Pirkėjo anuliuoti jo užsakymą, jei Pardavėjui nepavyksta susisiekti su Pirkėju."])},
    "section6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. Pardavėjo įsipareigojimai"])},
    "paragraph61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6.1. Pardavėjas įsipareigoja šiose Taisyklėse ir internetinėje parduotuvėje nustatytomis sąlygomis sudaryti Pirkėjui galimybę naudotis el. parduotuvės teikiamomis paslaugomis."])},
    "paragraph62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6.2. Pardavėjas įsipareigoja pristatyti Pirkėjo užsakytas prekes jo nurodytu adresu ir nurodytą dieną."])},
    "paragraph63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6.3. Pardavėjas, dėl svarbių aplinkybių negalėdamas pristatyti Pirkėjui užsakytos prekės, įsipareigoja grąžinti Pirkėjui sumokėtus pinigus, jeigu buvo atliktas išankstinis apmokėjimas."])},
    "paragraph64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6.4. Pardavėjas įsipareigoja pristatyti Pirkėjo užsakytas prekes jo nurodytu adresu Taisyklėse nurodytomis sąlygomis."])},
    "section7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. Prekių kainos, apmokėjimo tvarka ir terminai"])},
    "paragraph71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7.1. Prekių kainos el. parduotuvėje ir suformuotame užsakyme nurodomos eurais įskaitant tuo metu pagal teisės aktus galiojantį PVM dydį."])},
    "paragraph72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7.2. Už užsisakytas prekes Pirkėjas gali atsiskaityti vienu iš šių būdų:"])},
    "paragraph721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7.2.1. naudojantis elektronine bankininkyste;"])},
    "paragraph722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7.2.2. mokėjimo pavedimu;"])},
    "paragraph723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7.2.3. grynaisiais pinigais prekių pristatymo metu pirmą užsakymo dieną."])},
    "paragraph73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7.3. Pirkėjas, patvirtindamas Taisykles, sutinka, kad prekių pirkimo dokumentai – PVM sąskaitos faktūros, jam būtų pateikiamos elektroniniu būdu Pirkėjo registracijos formoje nurodytu elektroninio pašto adresu."])},
    "paragraph74": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["7.4. Pirkėjas ir Pardavėjas susitaria, kad Pirkėjui pateikus prekės užsakymą ir Pardavėjui jį patvirtinus, prekės kaina gali pakisti, atsižvelgiant į prekės kainai įtakos turinčius objektyvius rodiklius, tokius kaip informacinių sistemų techninė klaida ir su prekės pardavimu Pirkėjui susijusios papildomos išlaidos. Pirkėjui tokiu atveju nesutinkant įsigyti prekę nauja kaina, pirkėjas turi teisę nutraukti sutartį su pardavėju apie tai informuodamas elektroniniu paštu – ", _interpolate(_named("email")), ". Šalys susitaria, kad išlaidos, susijusios su pirkimo-pardavimo sutarties nutraukimu pirkėjui neatlyginami."])},
    "section8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. Prekių pristatymas"])},
    "paragraph81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.1. Užsakydamas prekes Pirkėjas gali pasirinkti prekių pateikimo būdą."])},
    "paragraph82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.2. Prekių pristatymo sąlygos bei įkainiai pateikti www.cityrush.lt svetainėje. Mygtuko “Užsakyti”; paspaudimas reiškia, kad pirkėjas susipažino su pristatymo sąlygomis bei įkainiais ir su jais sutinka."])},
    "paragraph83": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.3. Prekių pristatymas Pirkėjui:"])},
    "paragraph831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.3.1. Pirkėjas, užsakymo metu pasirinkęs prekių pristatymo paslaugą, įsipareigoja nurodyti tikslią prekių pristatymo vietą."])},
    "paragraph832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.3.2. Pirkėjas įsipareigoja prekes priimti pats. Tuo atveju, kai jis prekių pats priimti negali, o prekės pristatytos nurodytu adresu ir remiantis kitais Pirkėjo pateiktais duomenimis, Pirkėjas neturi teisės reikšti Pardavėjui pretenzijų dėl prekių pristatymo netinkamam subjektui."])},
    "paragraph833": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.3.3. Pirkėjas sutinka, jog išimtiniais atvejais prekių pateikimas gali vėluoti dėl nenumatytų, nuo Pardavėjo nepriklausančių aplinkybių. Tokiu atveju Pardavėjas įsipareigoja nedelsiant susisiekti su Pirkėju ir suderinti prekių pateikimo sąlygas."])},
    "paragraph834": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.3.4. Visais atvejais Pardavėjas atleidžiamas nuo atsakomybės už prekių pateikimo terminų pažeidimą, jeigu prekės Pirkėjui nėra pateikiamos arba pateikiamos ne laiku dėl trečiųjų asmenų kaltės arba dėl nuo Pirkėjo priklausančių aplinkybių."])},
    "paragraph835": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.3.5. Prekes pristato Pardavėjas arba jo įgaliotas atstovas"])},
    "paragraph84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.4. Prekių pateikimo Pirkėjui metu Pirkėjas privalo kartu su Pardavėju arba jo įgaliotu atstovu patikrinti siuntos ir prekės (-ių) būklę bei pasirašyti siuntos perdavimo – priėmimo dokumentą. Pirkėjui pasirašius siuntos perdavimo – priėmimo dokumente, laikoma, kad siunta yra perduota tinkamos būklės, prekių pažeidimų, kurių atsiradimo pagrindas priskirtinas ne gamykliniam brokui, bei prekės (-ių) komplektacijos neatitikimų (tokių, kuriuos galima nustatyti išorinės prekių apžiūros metu) nėra. Pastebėjęs, kad pateiktos siuntos pakuotė pažeista, prekė(-ės) yra pažeista (-os) ir/ar prekė (-ės) yra netinkamos komplektacijos, Pirkėjas privalo tai pažymėti siuntos perdavimo – priėmimo dokumente bei, dalyvaujant Pardavėjui ar jo atstovui, surašyti laisvos formos siuntos ir/ar prekės (-ių) pažeidimo/neatitikimų aktą. Pirkėjui neatlikus šių veiksmų, Pardavėjas yra atleidžiamas nuo atsakomybės dėl prekių pažeidimų, jeigu tokių pažeidimų atsiradimo pagrindas nėra gamyklinis brokas bei dėl prekių komplektacijos neatitikimų ir jei šiuos neatitikimus galima nustatyti išorinės prekių apžiūros metu."])},
    "paragraph85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5. Pirkėjas neturi teisės reikšti jokių pretenzijų Pardavėjui, o Pardavėjas nėra atsakingas Pirkėjui:"])},
    "paragraph851": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5.1. jeigu Pirkėjas neteisingai nurodė užsakyto maisto pristatymo adresą;"])},
    "paragraph852": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5.2. jeigu Pirkėjas neteisingai nurodė savo telefono numerį;"])},
    "paragraph853": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5.3. jeigu Pirkėjas neteisingai nurodė pageidaujamą užsakyto maisto pristatymo laiką;"])},
    "paragraph854": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5.4. jeigu Pirkėjas nepriėmė užsakyto maisto ne dėl Pardavėjo kaltės (neatidarė durų, neatsiliepė į telefono skambutį ir kt.);"])},
    "paragraph855": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5.5. jeigu Pirkėjas nesumoka Pardavėjui už užsakytą maistą arba atsisako mokėti už užsakytą maistą nesant tam objektyvių priežasčių;"])},
    "paragraph856": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5.6. dėl interneto svetainės www.cityrush.lt techninių gedimų, sutrikimų ir kitokio pobūdžio trikdžių;"])},
    "paragraph857": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5.7. dėl elektroninės bankininkystės gedimų, sutrikimų ir kitokio pobūdžio trikdžių;"])},
    "paragraph858": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.5.8. Pardavėjas turi teisę neperduoti užsakyto maisto Pirkėjui, jeigu yra nors viena iš Taisyklių 8.5.1-8.5.7 punkte nurodytų sąlygų."])},
    "paragraph86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8.6. Jei Pirkėjas neatsiima prekių per nustatytą terminą ar jų nepavyksta įteikti Pirkėjui ir Pirkėjas buvo sumokėjęs už prekes, atstovai susisiekia su Pirkėju dėl kito prekių pristatymo laiko ir/ar būdo. Jei Pirkėjas vis tiek neatsiima prekių arba nepavyksta jų įteikti, tokios prekės grąžinamos Pardavėjui, užsakymas anuliuojamas. Pirkėjui sumokėti pinigai atgal nėra grąžinami."])},
    "section9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. Prekių kokybės garantija ir tinkamumo naudoti terminas"])},
    "paragraph91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.1. Pardavėjas skirtingoms prekių rūšims suteikia tam tikrą laiką galiojančią daiktų kokybės garantiją, kurios konkretus terminas ir kitos sąlygos nurodomi tokių prekių aprašymuose."])},
    "paragraph92": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.2. Pardavėjas neatsako už tai, kad internetinėje parduotuvėje esančios prekės savo dydžiu, forma, spalva, ar kitais parametrais gali neatitikti realaus prekių dydžio, formų, spalvos ar kitų parametrų dėl Pirkėjo naudojamo elektroninio prietaiso techninių savybių."])},
    "paragraph93": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.3. Užsakytas maistas ir Pirkėjui pristatytas dėl maisto pakavimo specifikos ir taros išoriškai gali skirtis nuo el. svetainėje pateiktame valgiaraštyje nurodytų patiekalų nuotraukų."])},
    "section10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. Prekių grąžinimas ir keitimas"])},
    "paragraph101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.1. Jei Pirkėjas įsigijo netinkamos kokybės prekes arba prekių netinkama kokybė pasireiškia gamybiniu defektu, buvusiu prekių pirkimo metu, ar neatitikimu gamintojo nurodytoms specifikacijoms, Pirkėjas turi grąžinti prekes ir savo pasirinkimu gali reikalauti:"])},
    "paragraph1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.1.1. kad prekė būtų pakeista analogiška tinkamos kokybės preke, išskyrus atvejus, kai trūkumai yra nedideli arba jie atsirado dėl Pirkėjo kaltės;"])},
    "paragraph1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.1.2. kad būtų atitinkamai sumažinta pirkimo kaina;"])},
    "paragraph1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.1.3. kad Pardavėjas neatlygintinai per protingą terminą pašalintų prekės trūkumus arba atlygintų Pirkėjo išlaidas jiems ištaisyti, jei trūkumus įmanoma pašalinti;"])},
    "paragraph1014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.1.4. Pirkėjas gali pasirinkti tik vieną iš numatytų teisių gynimo būdų. Savo pasirinkimą Pirkėjas privalo pareikšti grąžindamas prekę. Jei Pirkėjui pasirinkus 10.1.2 punkte numatytą būdą, Pardavėjas neturi galimybės jo įgyvendinti, Pardavėjas siūlo alternatyvų 10.1 punkte numatytą būdą. Pirkėjas neturi teisės keisti pasirinkto teisių gynimo būdo."])},
    "paragraph102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2. Pirkėjui norint grąžinti prekes, būtina laikytis šių sąlygų:"])},
    "paragraph1021": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["10.2.1. pranešti apie tai Pardavėjui el. paštu ", _interpolate(_named("email")), ", pranešime privaloma nurodyti grąžinamas prekes;"])},
    "paragraph1022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2.2. pateikti prekių įsigijimo dokumentą;"])},
    "paragraph1023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2.3. pateikti laisvos formos prašymą."])},
    "paragraph1024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2.4. Teise grąžinti kokybės reikalavimų neatitinkančias prekes Pirkėjas gali pasinaudoti per 1 (vieną) darbo dieną nuo prekių pristatymo ar atsiėmimo dienos."])},
    "paragraph1025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2.5. Visos nemokamos dovanos, kurios buvo pateiktos kartu su įsigyta preke, turi būti grąžintos tuo pat metu."])},
    "paragraph1026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2.6. Pardavėjas turi teisę nepriimti Pirkėjo grąžinamų prekių, jei Pirkėjas nesilaiko Taisyklėse nustatytos prekių grąžinimo tvarkos."])},
    "paragraph1027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2.7. Prekes Pardavėjui reikia grąžinti adresu, kuris Pardavėjo nurodomas Pirkėjui atsiųstoje elektroninėje žinutėje, patvirtinančioje prekės grąžinimą."])},
    "paragraph1028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2.8. Pinigai Pirkėjui grąžinami per 10 (dešimt) darbo dienų po to, kai prekę gauna Pardavėjas."])},
    "paragraph1029": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.2.9. Negrąžinami pinigai už tas prekes, kurios tyčia ar dėl neatsargumo buvo sugadintos (paveiktos atvira ugnimi, aukšta temperatūra, aštriais objektais, cheminėmis medžiagomis, vandeniu ir t.t.), arba jeigu buvo pažeistos prekės naudojimo ar saugojimo taisyklės, arba prekės naudotos netinkamai ar ne pagal paskirtį."])},
    "section11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. Atsakomybė"])},
    "paragraph111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.1. Pirkėjas yra visiškai atsakingas už jo pateikiamų Asmens duomenų teisingumą. Jei Pirkėjas pateikia neteisingus ar netikslius asmens duomenis, Pardavėjas neatsako už dėl to atsiradusius padarinius ir įgyja teisę reikalauti iš Pirkėjo patirtų tiesioginių nuostolių atlyginimo."])},
    "paragraph112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.2. Pirkėjas atsako už veiksmus, atliktus naudojantis el. parduotuve."])},
    "paragraph113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.3. Užsiregistravęs Pirkėjas atsako už savo prisijungimo duomenų saugojimą ir/ar perdavimą tretiesiems asmenims. Jei www.cityrush.lt teikiamomis paslaugomis naudojasi trečiasis asmuo, prisijungęs prie internetinės parduotuvės naudodamasis Pirkėjo prisijungimo duomenimis, Pardavėjas šį asmenį laiko Pirkėju."])},
    "paragraph114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.4. Pardavėjas atleidžiamas nuo bet kokios atsakomybės tais atvejais, kai nuostoliai kyla dėl to, jog Pirkėjas, neatsižvelgdamas į Pardavėjo rekomendacijas ir savo įsipareigojimus, nesusipažino su šiomis Taisyklėmis, nors tokia galimybė jam buvo suteikta."])},
    "paragraph115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.5. Jei Pardavėjo el. parduotuvėje yra pateiktos nuorodos į kitų trečiųjų asmenų tinklapius, tai Pardavėjas negarantuoja, kad informacija, kurią galima peržiūrėti paspaudus šias nuorodas, yra teisinga, išsami ar tiksli. Už trečiųjų asmenų pateikiamos informacijos turinį, jos teisingumą, išsamumą ir tikslumą atsakingi tretieji asmenys. Pardavėjas nėra įpareigotas patikrinti perduodamą ar saugomą išorinę informaciją arba nustatyti neteisėtus veiksmus."])},
    "section12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13. Baigiamosios nuostatos"])},
    "paragraph121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.1. Pardavėjas turi teisę pakeisti ir papildyti šias Taisykles. Pirkėjas kiekvieną kartą, prieš naudodamasis maisto užsakymo paslauga, privalo susipažinti su Taisyklėmis ir patvirtinti, jog sutinka jų laikytis. Jei Pirkėjas pradėjo pildyti užsakymo formą, laikoma, kad pirkėjas sutiko su visomis Maisto užsakymo taisyklėmis. Pardavėjas neprisiima atsakomybės, jei Pirkėjas pateikė užsakymą, nesusipažinęs su šiomis Taisyklėmis ar jų dalimi."])},
    "paragraph122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.2 Pirkti elektroninėje parduotuvėje www.cityrush.lt turi teisę tik Pirkėjai, kaip jie apibrėžti Taisyklių 1.3 punkte. Pirkėjas, patvirtindamas Taisykles, patvirtina, kad jis turi teisę pirkti prekes elektroninėje parduotuvėje www.cityrush.lt."])},
    "paragraph123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.3. Šios taisyklės sudarytos vadovaujantis Lietuvos Respublikos teisės aktais. Šių Taisyklių pagrindu kylantiems santykiams taikoma Lietuvos Respublikos teisė."])},
    "paragraph124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.4. Visi nesutarimai, kilę dėl šių Taisyklių vykdymo, sprendžiami derybų būdu. Nepavykus susitarti per 30 (dvidešimt) kalendorinių dienų, ginčai sprendžiami Lietuvos Respublikos teisės aktų nustatyta tvarka."])},
    "section13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. Nuolaidos kodų naudojimo taisyklės"])}
  },
  "privacyPolicy": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas mes esame"])},
    "paragraph11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mūsų tinklalapio adresas yra: https://cityrush.lt"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokius asmeninius duomenis mes renkame ir kodėl tai darome"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarai"])},
    "paragraph31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kai lankytojai rašo komentarus tinklalapyje, mes renkame duomenis, matomus komentaro paskelbimo formoje, o taip pat lankytojo IP adresą bei naršyklės vartotojo įrašą, kad apsisaugotume nuo brukalų."])},
    "paragraph32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iš Jūsų el.pašto adreso gali būti sugeneruotas anonimizuotas duomenų įrašas (angl. hash) ir pateiktas Gravatar paslaugos teikėjui, norint patikrinti, ar Jūs naudojatės šia paslauga. Gravatar privatumo politika pateikiama čia: https://automattic.com/privacy/. Patvirtinus Jūsų komentarą, Jūsų profilio nuotrauka yra matoma viešai šalia Jūsų komentaro."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failai"])},
    "paragraph41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jei įkeliate nuotraukas į tinklalapį, turėtumėte vengti įkelti jas su išsaugotais vietovės duomenimis (EXIF GPS). Tinklalapio lankytojai gali parsisiųsti nuotraukas iš tinklalapio ir iš jų išgauti vietovės duomenis."])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slapukai (cookies)"])},
    "paragraph51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kai Jūs rašote komentarą šiame tinklalapyje, Jūs galite pasirinkti, ar norite, kad Jūsų vardas, el.paštas ir tinklalapis būtų išsaugotas slapukuose. Taip daroma Jūsų patogumui, kad Jums nereikėtų šių duomenų suvedinėti iš naujo, kiekvieną kartą kai komentuojate. Šie slapukai saugomi vienerius metus."])},
    "paragraph52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jei Jūs turite paskyrą ir prisijungiate mūsų tinklalapyje, mes sukuriame laikiną slapuką, kuriame įrašoma, ar Jūsų naršyklė priima slapukus. Šis slapukas nesaugo jokios asmeninės informacijos ir yra ištrinamas kai uždarote savo naršyklę."])},
    "paragraph53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kai Jūs prisijungiate, mes taip pat sukuriame kelis slapukus, kuriuose saugoma Jūsų prisijungimo informacija ir Jūsų ekrano rodymo pasirinkimai. Prisijungimo slapukai galioja 2 dienas, o ekrano nustatymų – vienerius metus. Jei Jūs pažymite pasirinkimą “Prisiminti mane”, Jūsų prisijungimo slapukas galios 2 savaites. Kai atsijungiate iš savo paskyros, prisijungimo slapukai ištrinami."])},
    "paragraph54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jei redaguojate ar paskelbiate įrašą, Jūsų naršyklėje išsaugomas papildomas slapukas. Šis slapukas nesaugo asmeninių duomenų, tačiau išsaugo įrašo, kurį ką tik redagavote ar paskelbėti, ID. Šis slapukas baigia galioti po 1 dienos."])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iš kitų tinklalapių įkeltas turinys"])},
    "paragraph61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šiame tinklalapyje gali būti iš kitų tinklalapio įkelto turinio (pavyzdžiui, video, paveikslėliai, tekstas ir pan.). Tokio įkelto turinio peržiūra ir veiksmai su juo šiame tinklalapyje prilygsta apsilankymui tuose tinklalapiuose, iš kurių turinys yra įkeltas."])},
    "paragraph62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šie tinklalapiai gali rinkti duomenis apie Jus, naudoti slapukus (cookies), trečiųjų šalių sekimo paslaugas ir stebėti Jūsų veiksmus su įkeltu turiniu, įskaitant ir tuos atvejus, kai turite paskyrą ir esate prisijungęs prie minėtų tinklalapių."])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaip ilgai mes saugome Jūsų duomenis"])},
    "paragraph71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jei parašote komentarą, jo tekstas ir metaduomenys yra saugomi neribotą laiką. Taip daroma todėl, kad galėtume atpažinti ir patvirtinti ateities komentarus automatiškai, vietoje administratoriaus patvirtinimo."])},
    "paragraph72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kai užsiregistruojate mūsų tinklalapyje ir tampate vartotoju, mes saugome visus Jūsų asmeninius duomenis, kuriuos Jūs pateikiate savo paskyroje. Kiekvienas vartotojas gali matyti, redaguoti ir ištrinti savo asmeninius duomenis bet kuriuo metu (išskyrus vartotojo vardą). Tinklalapio administratoriai tai pat gali matyti ir redaguoti šiuos duomenis."])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsų teisės"])},
    "paragraph81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jei Jūs turite paskyrą šiame tinklalapyje, arba kada nors rašėte čia komentarą, galite reikalauti gauti duomenų eksporto failą su visais asmeniniais duomenimis, kuriuos mes turime apie Jus, įskaitant ir tuos, kuriuos pats mums pateikėte. Jūs taip pat galite reikalauti, kad mes ištrintume visus mūsų turimus Jūsų asmeninius duomenis. Šie abu reikalavimai negalioja duomenims, kuriuos mes privalome išsaugoti pagal įstatymą administraciniams, teisiniams ar saugumo tikslams."])},
    "title9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kur mes siunčiame Jūsų duomenis"])},
    "paragraph91": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lankytojų komentarai gali būti tikrinami naudojant automatinę brukalų paieškos paslaugą."])}
  },
  "dataDeletionPolicy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duomenų pašalinimo taisyklės"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet kokie duomenys, kurie yra susiję su šios svetainės vartotoju yra pašalinami iš šios svetainės minėto vartotojo prašymu. Šie duomenys gali apimti vartotojo tikrąjį vardą, pavardę, el. pašto adresą, telefono numerį, adresus ar bet kokius kitus su vartotoju susijusius duomenis esančius šio vartotojo paskyroje."])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taipogi vartotojas savo prašymų iš svetainės gali panaikinti vieną ar kelias savo turimas paskyras."])}
  },
  "discountPoints": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidų taškai"])},
    "theAmountOfPointsHaveExpiresAfter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jūsų turimas taškų kiekis (galioja iki ", _interpolate(_named("date")), ")"])},
    "referralLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siuntimo nuoroda"])},
    "linkToShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuoroda dalinimuisi"])},
    "copyThisLinkAndShare": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nukopijuokite šią nuorodą ir pasidalykite su kitais. Kai naujas vartotojas įsigys savo pirmąjį pirkinį, gausite taškus. Taškų kiekis už sėkmingą rekomendaciją - ", _interpolate(_named("amount"))])},
    "readMoreAboutDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skaitykite daugiau apie nuolaidų taškus"])},
    "maximumAllowedOrderPoints": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimalus panaudojamų taškų vertės dydis (", _interpolate(_named("percentage")), "% rinkinių vertės) bus taikomas automatiškai"])}
  },
  "referral": {
    "referralLinkRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registracija per rekomendacijos nuorodą"])},
    "weAreSorryCanNotRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apgailestaujame, bet negalite registruotis naudodamiesi rekomendacijos nuoroda"])}
  },
  "kitOrderSetting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinkinio užsakymo nustatymai"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukimas"])},
    "disableUserCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išjungti vartotojo atšaukimą"])},
    "cancellationTermInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukimo terminas (valandomis)"])},
    "enterCancellationTermInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite atšaukimo terminą (valandomis)"])},
    "amountOfHoursBeforeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valandų skaičius iki pristatymo, kada vartotojas gali atšaukti rinkinio užsakymą"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keisti"])},
    "disableChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išjungti pakeitimus"])},
    "termForChangeHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakeitimo laikotarpis (valandomis)"])},
    "enterTermForChangeHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite pakeitimo laikotarpį (valandomis)"])},
    "timeAfterWhichChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laikas, po kurio bus atliktas užsakymo pakeitimas (nuo momento kai vartotojas atlieka pakeitimą)"])},
    "deliveryDateChangeLimitHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pristatymo datos pakeitimo terminas (valandomis)"])},
    "enterDeliveryDateChangeLimitHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įveskite pristatymo datos pakeitimo terminą (valandomis)"])},
    "howManyHoursBeforeStartOfDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiek valandų iki pristatymo pradžios vartotojas gali pakeisti pristatymo datą"])}
  },
  "kitManagement": {
    "has_canceled_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirkėjas atšaukė užsakymą"])},
    "has_canceled_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teikėjas atšaukė užsakymą"])},
    "orderCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymas atšauktas"])},
    "cancellationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukimo užklausa"])},
    "cancellationRequestSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netrukus susisieksime su jumis dėl atšaukimo sąlygų"])},
    "regularOrderCancellationSubtitle1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Užsakymo (arba atnaujinto užsakymo) atšaukimas turėtų būti atliktas ", _interpolate(_named("hours")), " valandas iki pristatymo, kitu atveju tos dienos rinkinys bus pristatytas ir mokėjimas už tą dieną nebus grąžintas"])},
    "regularOrderCancellationSubtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apskaičiuojant grąžinamą sumą, kiekvienam pristatytam rinkiniui bus taikoma pilna dienos užsakymo kaina. Atėmus atliktus pristatymus liks grąžinama suma. Apskaičiuojant grąžinamą sumą bus atsižvelgta ir į pritaikytas nuolaidas"])},
    "amountToBeReturned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grąžintina suma (taikoma tik jei mokėjimas atliktas, taip pat galimi gražinimai už pristatymo išlaidas)"])},
    "amountReturned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grąžinta suma"])},
    "confirmReturnedAmountModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar tikrai norite patvirtinti grąžintą sumą?"])},
    "sendCancelationRequestModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar tikrai norite siųsti šio užsakymo atšaukimo užklausą?"])},
    "cancelOrderModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ar tikrai norite atšaukti šį užsakymą?"])},
    "continueOrderModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Ar tikrai norite tęsti šį užsakymą?"])},
    "cancelOrderModalDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jums bus grąžinta ", _interpolate(_named("amount")), " Eur suma už rinkinį (galimi papildomi grąžinimai ir už pristatymo išlaidas)"])},
    "regularOrderStatuses": {
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laukiama patvirtinimo"])},
      "canceled_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukta teikėjo"])},
      "canceled_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukta vartotojo"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumokėta"])},
      "not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesumokėta"])}
    },
    "individualOrderStatuses": {
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laukiama patvirtinimo"])},
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patvirtinta"])},
      "cancellation_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukimo užklausa"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atšaukta"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumokėta"])},
      "not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesumokėta"])}
    },
    "allChangesApartFromContactsWouldBeDoneAfter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visi pakeitimai, išskyrus kontaktus, bus atlikti po ", _interpolate(_named("hours")), " valandų nuo pakeitimo momento"])},
    "allChangesApartFromDeliveryWouldBeDoneAfter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visi pakeitimai, išskyrus pristatymą, būtų padaryti po ", _interpolate(_named("hours")), " val. nuo pakeitimo momento"])},
    "regularKitSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguliaraus rinkinio pasirinkimai"])},
    "ifChangeIsMadeToRegularKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jei pakeitimai atliekami reguliariam rinkiniui, jie būtų taikomi visiems reguliariems rinkiniams užsakyme, nes pristatymo sąlygos yra vienodos visiems reguliariems rinkiniams"])},
    "youCanChangeDeliveryDateBefore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Galite pakeisti pristatymo datą ir pristatymo laiką ", _interpolate(_named("hours")), " val. prieš pristatymą"])}
  },
  "EUSupport": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauji e-komercijos sprendimai UAB ,Londesa“"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAB ,,Londesa“ nuo 2022 m. vasario mėnesio įgyvendina projektą, skirtą e-komercijos modelio diegimui. Siekiant vykdyti pelningą veiklą Covid-19 paveiktoje rinkoje, taip pat didinti teikiamų paslaugų patrauklumą bei darbuotojų darbo našumą, projekto apimtyje bus sukurta elektroninė klientų savitarnos sistema. Modernioje mobilioje aplikacijoje klientai savarankiškai galės užsisakyti maisto prenumeratą (Vilniaus ir Kauno miestuose) ir ją apmokėti, taip pat dalyvauti apklausose (gerinant asmeninių prenumeratų kokybę), tiesiogiai bendrauti su įmonės personalu kilus problemoms bei gauti svarbius su užsakymu susijusius pranešimus."])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sėkmingai įgyvendinti projekto rezultatai leis klientams paslaugas gauti greičiau ir patogiau, o įmonei atsiras galimybė efektyviau valdyti užsakymų srautus bei sekti siūlomų paslaugų ir parduodamos produkcijos paklausą, labiau atliepiant klientų poreikius bei lūkesčius."])},
    "paragraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektas finansuojamas iš Europos regioninės plėtros fondo, kaip Europos Sąjungos atsako į COVID-19 pandemiją priemonė."])},
    "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekto pavadinimas:"])},
    "projectTitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAB ,,Londesa“ e-komercijos modelio diegimas"])},
    "projectNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekto numeris:"])},
    "projectNumberText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.1.1-LVPA-K-860-01-1067"])},
    "projectStarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekto pradžia:"])},
    "projectStartsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 m. vasario 15 d."])},
    "projectEnds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekto pabaiga:"])},
    "projectEndsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 m. sausio 8 d."])},
    "projectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekto vertė:"])},
    "projectValueText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22 000 Eur (iš jų paramos lėšų – 16 500 Eur)."])}
  },
  "cities": {
    "preOrderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pranešimas kai išankstinis užsakymas įjungtas."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavadinimas"])}
  },
  "pages": {
    "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puslapiai"])},
    "noPagesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puslapių nėra"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puslapio pavadinimas"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turinys"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puslapio adresas"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puslapiai"])},
    "noCitiesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miestų nėra."])},
    "cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miestai"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miestas"])}
  },
  "checkout": {
    "info_text": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsiregistravus tu galėsi:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matyti ir valdyti savo užsakymą"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žinosi tiklsiai, kada yra paskutinė tavo užsakymo diena"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaupsi lojalumo taškus"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" P.S. Atlikus užsakymą be registracijos, vėliau jo savo paskyroje nematysi."])}
    }
  },
  "errros": {
    "i_agreen_with_contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privalote sutikti su mano nurodytais kontaktais, gauti informaciją dėl mano užsakymo vykdymo eigos."])}
  }
}