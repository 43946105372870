import LocalStorage from '@/helpers/LocalStorageHelper';
import {
    getMealKitAllPrices,
    applyDiscountCodeToCartItem,
    updateAppliedDiscountCodeInCartItem,
} from '@/helpers/CartHelper';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

// Pridedame galiojimo tikrinimo funkciją
function isCartItemExpired(cartItem) {
    const now = new Date();

    // Jei nėra sukūrimo datos (createdAt), laikome, kad elementas pasibaigęs
    if (!cartItem.createdAt) {
        return true;
    }

    const createdAt = new Date(cartItem.createdAt);
    const oneDayInMs = 24 * 60 * 60 * 1000; // 1 diena milisekundėmis

    // Tikriname, ar praėjo daugiau nei 1 diena nuo sukūrimo
    return (now - createdAt) > oneDayInMs;
}

export const cart = {
    namespaced: true,
    state: {
        cart: [],
        discountCodes: [],
        selectedMealKit: null,
        isOpened: false,
        editMode: {
            isOpened: false,
            cartItemId: null,
        },
    },
    getters: {
        cart: (state) => {
            return state.cart;
        },
        isOpened: (state) => {
            return state.isOpened;
        },
        editMode: (state) => {
            return state.editMode;
        },
        selectedMealKit: (state) => {
            return state.selectedMealKit;
        },
        discountCodes: (state) => {
            return state.discountCodes;
        },
        isDiscountCodesPresent: (state) => {
            return !!state.discountCodes.length;
        },
        uniqueKitsInCart: (state) => {
            return state.cart.filter((item) => item.mealKit.isUnique);
        },
        isUniqueKitInCart: (state) => {
            return !!state.cart.find((item) => item.mealKit.isUnique);
        },
        isRegularKitInCart: (state) => {
            return !!state.cart.find((item) => !item.mealKit.isUnique);
        },
    },
    mutations: {
        // Toggle
        setIsOpened(state, isOpened) {
            state.isOpened = isOpened;
        },

        setEditMode(state, editMode) {
            state.editMode = editMode;
        },

        selectMealKit(state, mealKit) {
            state.selectedMealKit = mealKit;
        },

        // Cart
        setCart(state, cart) {
            state.cart = cart;
        },

        addCartItem(state, payload) {
            payload.totalPrice = getMealKitAllPrices(payload).totalPrice;
            payload.mealKitPrice = getMealKitAllPrices(payload).mealKitPrice;
            payload.appliedDiscountCodes = [];

            payload.id = uuidv4();
            payload.time = Date.now();
            payload.createdAt = new Date().toISOString(); // Sukūrimo data

            LocalStorage.create(`cart-item-${payload.id}`, payload);

            state.cart.push(payload);
        },

        updateCartItem(state, payload) {
            let neededItem = state.cart.find((item) => item.id === payload.id);

            Object.assign(neededItem, payload);

            neededItem.totalPrice = getMealKitAllPrices(neededItem).totalPrice;
            neededItem.mealKitPrice = getMealKitAllPrices(neededItem).mealKitPrice;
            updateAppliedDiscountCodeInCartItem(neededItem);
            neededItem.time = Date.now();
            LocalStorage.create(`cart-item-${neededItem.id}`, neededItem);
        },

        removeCartItem(state, cartItemId) {
            state.cart = state.cart.filter((item) => item.id !== cartItemId);
        },

        // Discount codes
        setDiscountCodes(state, discountCodes) {
            state.discountCodes = discountCodes;
        },

        addDiscountCode(state, discountCode) {
            state.discountCodes.push(discountCode);

            applyDiscountCodeToCartItem(discountCode, state.cart);

            Cookies.set('discount-codes', state.discountCodes, { expires: 1 / 24 });
        },

        removeDiscountCode(state, discountCodeId) {
            const discountCode = state.discountCodes.find((discountCode) => discountCode.id === discountCodeId);

            applyDiscountCodeToCartItem(discountCode, state.cart, 'remove');

            state.discountCodes = state.discountCodes.filter((discountCode) => discountCode.id !== discountCodeId);

            Cookies.set('discount-codes', state.discountCodes, { expires: 1 / 24 });
        },
    },
    actions: {
        removeAllDiscountCodes({ dispatch, state }) {
            // Nukopijuojame nuolaidos kodų ID sąrašą, kad išvengtume modifikacijos per iteraciją
            const discountCodeIds = [...state.discountCodes.map(dc => dc.id)];
    
            discountCodeIds.forEach(discountCodeId => {
                dispatch('removeDiscountCode', discountCodeId);
            });
        },
        filterDiscountCodesByCity({ commit, state }, cityId) {
            // Naudokime esamą nuolaidos kodų sąrašą ir atnaujinkime jį pagal pasirinktą miestą
            state.discountCodes.forEach((discountCode) => {
                // Jei nėra miestų arba kodas galioja visiems miestams, jis išlieka
                if (!discountCode.citiesIds || discountCode.citiesIds.length === 0) {
                    return;
                }

                // Jei miestų sąrašas yra ir miestas nėra sąraše, pašaliname nuolaidos kodą
                if (!discountCode.citiesIds.includes(cityId)) {
                    commit('removeDiscountCode', discountCode.id);
                }
            });

            // Atnaujiname nuolaidos kodų sąrašą saugykloje
            Cookies.set('discount-codes', state.discountCodes, { expires: 1 / 24 });
        },

        // Toggle
        toggleCart({ commit }, data) {
            commit('setIsOpened', data.isOpened);
            commit('selectMealKit', data.mealKit);
        },

        toggleEditMode({ commit }, data) {
            commit('setIsOpened', data.isOpened);
            commit('setEditMode', data.editMode);
            commit('selectMealKit', data.mealKit);
        },

        // Cart
        setCart({ commit, dispatch }) {
            const allCookies = { ...localStorage };
            const cartCookies = [];

            Object.entries(allCookies).forEach(([key, val]) => {
                if (key.includes('cart')) {
                    const cartItem = JSON.parse(val);

                    // Tikriname, ar krepšelio elementas nepasibaigęs arba neturi galiojimo datos
                    if (!isCartItemExpired(cartItem)) {
                        cartCookies.push(cartItem);
                    } else {
                        // Pašaliname pasibaigusį arba neturintį galiojimo datos elementą
                        LocalStorage.delete(key);
                        
                    }
                }
            });

            commit('setCart', cartCookies);

            // Jei krepšelis yra tuščias, pašalinkite visus nuolaidos kodus
            if (cartCookies.length === 0) {
                dispatch('removeAllDiscountCodes');
            }

            dispatch('setDiscountCodes');
        },

        addMealKitInCart({ commit, state }, payload) {
            let isSimilarObjFound = [];
            const payloadCopy = { ...payload };
            delete payloadCopy.amount;

            state.cart.forEach((element) => {
                const elementCopy = { ...element };
                delete elementCopy.id;
                delete elementCopy.amount;
                delete elementCopy.totalPrice;
                delete elementCopy.mealKitPrice;
                delete elementCopy.appliedDiscountCodes;

                if (JSON.stringify(elementCopy) === JSON.stringify(payloadCopy)) {
                    payload.id = element.id;
                    payload.amount = element.amount + payload.amount;
                    commit('updateCartItem', payload);

                    isSimilarObjFound.push(true);
                }
            });

            if (!isSimilarObjFound.includes(true)) {
                commit('addCartItem', payload);
            }
        },

        updateMealKitInCart({ commit }, data) {
            commit('updateCartItem', data);
        },

        removeMealKitFromCart({ commit, state }, cartItemId) {
            LocalStorage.delete(`cart-item-${cartItemId}`);

            const cartItem = state.cart.find((item) => item.id === cartItemId);

            commit('removeCartItem', cartItemId);

            cartItem.appliedDiscountCodes.forEach((discountCode) => {
                commit('removeDiscountCode', discountCode.id);
            });
        },

        // Discount codes
        setDiscountCodes({ commit }) {
            const discountCodesCookie = Cookies.get('discount-codes');

            if (discountCodesCookie) {
                commit('setDiscountCodes', JSON.parse(discountCodesCookie));
            }
        },

        addDiscountCode({ commit }, payload) {
            console.log(payload);

            commit('addDiscountCode', payload);
        },

        removeDiscountCode({ commit }, discountCodeId) {
            commit('removeDiscountCode', discountCodeId);
        },

        clearCartCookies({ commit }) {
            const allCookies = { ...localStorage };

            Object.entries(allCookies).forEach((element) => {
                if (element[0].includes('cart')) {
                    LocalStorage.delete(element[0]);
                }
            });

            Cookies.remove('discount-codes');

            commit('setDiscountCodes', []);
            commit('setCart', []);
        },
    },
};
